<template>
  <v-container fluid>
    <editor :initial="dataItem" @data="save"></editor>
  </v-container>
</template>

<script>
import editor from "../_components/init-editor.vue";
export default {
  components: {
    editor,
  },
  data() {
    return {
      dataItem: {},
    };
  },
  methods: {
    save(data) {
      const url = `/company_details/1`;
      this.$store
        .dispatch("put", { url, data })
        .then(() => {
          location.reload();
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDefaults() {
      const self = this;
      this.$store
        .dispatch("get", `/company_details`)
        .then((res) => {
          self.dataItem = res.ResponseData;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created () {
    this.getDefaults();
  },
};
</script>

<style lang="scss" scoped>
</style>