<template>
  <v-row dense>
    <v-col cols="4">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            dense
            outlined
            v-model="record.MainCurncy"
            :items="currencies"
            label="Local Currency"
            item-text="currency"
            item-value="id"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            dense
            outlined
            v-model="record.SysCurrncy"
            :items="currencies"
            label="System Currency"
            item-text="currency"
            item-value="id"
          ></v-autocomplete>
        </v-col>

        <!-- <v-col cols="12">
          <v-autocomplete
            dense
            outlined
            v-model="record.DftItmsGrpCod"
            :items="itemGroups"
            label="Items Group"
            item-text="ItmsGrpNam"
            item-value="id"
          ></v-autocomplete>
        </v-col> -->

        <v-col cols="12">
          <v-autocomplete
            dense
            outlined
            v-model="record.InvntSystm"
            :items="valMethods"
            label="Items Group Valuation Method"
            item-text="name"
            item-value="value"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12">
          <v-btn color="accent" :loading="loader" @click="sendData">
            <v-icon left></v-icon>Save
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    initial: {
      type: Object
    }
  },
  components: {

  },
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters("currency", ["currencies"]),
  },
  data: () => ({
    record: {},
    loader: false,
    itemGroups: [],
    valMethods: [
      { name: "Moving Average", value: "A" },
      { name: "Standard", value: "S" },
      { name: "FIFO", value: "F" },
    ],
  }),
  watch: {
    initial: {
      handler: "setRecord",
      immediate: true
    }
  },
  methods: {
    setRecord(val) {
      this.record = { ...val };
    },
    sendData() {
      const data = this.record;
      this.loader = true;
      this.$emit("data", data);
    },
    getItemGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.itemGroups = res;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getItemGroups();
  },
};
</script>